.MuiInputBase-root.Mui-disabled {
    background-color: lighten($primary-bg, 5);
}

form {
    // .MuiInputBase-root {
    //   background-color: white;
    // }

    input,
    textarea {
        width: 100% !important;
        padding: 5px 10px;
        font-size: 16px;
        height: 38px;
        transition: border 0.3s;
        background: white !important;
        border-radius: 3px;

        &::-webkit-input-placeholder {
            color: $color-additional;
        }
        &::-moz-placeholder {
            color: $color-additional;
        }
        /* Firefox 19+ */
        &:-moz-placeholder {
            color: $color-additional;
        }
        /* Firefox 18- */
        &:-ms-input-placeholder {
            color: $color-additional;
        }

        // border: solid 1.5px $color-border;

        &[disabled] {
            background: #f2f4f7;

            &:focus,
            &:active {
                border-color: $color-border;
            }
        }

        &:focus,
        &:active {
            outline: none;
            border-color: $primary-color;
        }
    }
    input[type='checkbox'] {
        height: 25px;
        display: flex;
        justify-content: space-around;
        // align-self: flex-start;
    }

    textarea {
        min-height: 85px;
    }
}

.form__form-group {
    margin-bottom: 20px;
    width: 100%;
    position: relative;
}

.form__form-group-field {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.form__form-group-label {
    margin-bottom: 4px;
    display: inline-block;
    color: $primary-text;

    span {
        color: #ddd;
    }
}

.form__form-group-file {
    display: flex;
    justify-content: center;
    label {
        padding: 4px 20px;
        cursor: pointer;
        transition: all 0.3s;
        text-align: center;
        height: 38px;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: none !important;
        border: solid 1.5px $primary-color !important;
        background-color: $primary-color !important;
        color: #fff;
        font-size: 1rem;
        min-width: 150px;

        &:hover {
            background-color: $primary-color-hover !important;
            border: solid 1.5px $primary-color-hover !important;
        }
    }

    .file__placeholder {
        display: flex;
        align-items: center;
        border: solid 1.5px $color-border;
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
        padding: 0 20px 0 10px;
        height: 38px;
        color: rgba(0, 0, 0, 0.54);
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.00938em;
        background-color: #fff;
        overflow: hidden;
        width: 25vw;
    }

    span {
        padding-left: 0;
        color: $primary-text;
        font-weight: 400;
    }

    // input {
    //   display: none;
    // }
}

.form__form-group-error {
    margin-left: 0px;
    /* &::before{
        content: "!";
        position: absolute;
        font-size: 1.3rem;
        font-weight: bold;
        right: 20px;
        bottom: 30px;
      }*/

    /*&::after{
        content: "!";
        position: absolute;
        font-size: 0.8rem;
        font-weight: bold;
        left: 10px;
        bottom: 2px;
      }*/
}

.form__form-group-select-error {
    margin-left: 0px;
    /*&::before{
        content: "!";
        position: absolute;
        font-size: 1.3rem;
        font-weight: bold;
        right: 35px;
        bottom: 30px;
      }*/

    /*&::after{
        content: "!";
        position: absolute;
        font-size: 0.8rem;
        font-weight: bold;
        left: 15px;
        bottom: 0;
      }*/
}

.form__form-group-input-wrap--error {
    &.error {
        .form__form-group-datepicker {
            border-color: $color-error;
        }
    }
}

.form__form-group-error-chkbox {
    position: relative;
    left: 10px;
    // margin-left: 30px;
    margin-top: 10px;
    color: $color-error;
    &.datepicker__error-message {
        left: 10px;
    }
    /*&::after{
        content: "!";
        position: absolute;
        font-size: 0.8rem;
        font-weight: bold;
        left: -10px;
        bottom: 2px;
      }*/
}

.form__form-group-icon {
    padding: 6px;
    height: 48px;
    background-color: transparent;
    color: $color-additional;
    font-weight: 500;
    font-size: 14px;
    border: solid 1.5px $color-border;
    border-radius: 5px;
    border-left: none;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        fill: $color-additional;
        width: 18px;
        height: 18px;
        transition: all 0.3s;
    }
}

.form__form-group-input-wrap {
    // width: 100% !important;
    &.uploaded label {
        background-color: $alternate-bg !important;
    }
}

.form__form-group-input-wrap--error {
    color: $color-error;
}

.from__form-group-label {
    font-size: 0.9rem;
    font-weight: bold;
}

.form__form-group-input-wrap-haserror {
    & input,
    textarea {
        border-color: $color-error !important;
    }
}

.react-datepicker__day--selected {
    background-color: $primary-color !important;
    &:hover {
        background-color: $primary-color-hover;
    }
    &:focus {
        outline: none;
    }
}

.react-datepicker__day {
    &:hover {
        background-color: $primary-color;
    }
}

.react-datepicker__day--disabled {
    &:hover {
        color: #ccc;
        background: transparent;
    }
}

.react-datepicker__header {
    background-color: $primary-color !important;
    border-color: $primary-color;
    border-radius: 0;
    .react-datepicker__day-name {
        color: $primary-text;
    }
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker__day-name,
.react-datepicker__time-name {
    color: #ffffff;
}

.react-datepicker__navigation--next {
    display: none;
    border-left-color: #ffffff;

    &:hover {
        border-left-color: #ffffff;
    }
}

.react-datepicker__navigation--previous {
    display: none;
    border-right-color: #ffffff;

    &:hover {
        border-right-color: #ffffff;
    }
}

.react-select__input {
    color: transparent !important;
}

.react-datepicker__month-read-view--selected-month {
    color: #fff;
    font-size: 1rem;
    margin-right: 5px;
}

.react-datepicker__year-read-view--selected-year {
    color: #fff;
    font-size: 1rem;
    margin-right: 5px;
}

.react-datepicker__current-month {
    display: none;
}

.react-datepicker__year-option {
    &:hover {
        background-color: $primary-color-hover;
    }
}
.react-datepicker__year-option--selected_year {
    color: #fff;
    background-color: $primary-color;
}

.react-datepicker__month-option {
    &:hover {
        background-color: $primary-color-hover;
    }
}
.react-datepicker__month-option--selected_month {
    color: #fff;
    background-color: $primary-color;
}

.react-datepicker__day--keyboard-selected {
    background-color: $primary-color !important;
    &:hover {
        background-color: $primary-color-hover;
    }
    &:focus {
        outline: none;
    }
}

.react-datepicker__triangle {
    border-bottom-color: $primary-color !important;
    margin-top: -8px;
    transform: translate(230px, 1px) !important;
    &::after {
        border-bottom-color: $primary-color !important;
    }
    &::before {
        border-bottom-color: #aeaeae !important;
    }
}

.date-picker {
    width: 100%;
}

.react-datepicker-wrapper {
    width: 100%;
}

.form__form-group-datepicker {
    width: 100%;
}

.selectHasError {
    .form__form-group-file {
        .file__placeholder {
            border-color: $color-error !important;
        }
    }
    .react-select__control {
        border: solid 1.5px $color-error !important;
    }
}

.form__form-group_checkbox-field {
    display: flex;
    justify-content: flex-start;
    @media (min-width: 600px) {
        display: grid;
        grid-template-columns: 1fr 8fr;
        // width: 70%;
    }
    @media (min-width: 992px) {
        width: 100%;
    }
}

.claim__calendar-icon {
    position: absolute;
    right: 0px;
    top: 28px;
    width: 38px;
    height: 36px;
    padding: 7px;
    color: #fff;
    z-index: -1;
    background: $primary-color;
    @media (min-width: 768px) {
        top: 28px;
    }
}

.checkbox__form {
    width: fit-content;
    label {
        display: flex;
        align-items: center;
        input {
            width: 20px;
            margin-right: 20px;
        }
        span {
            font-size: 1rem;
        }
    }
}
