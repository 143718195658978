$primary-color: #ac0209;
$primary-color-hover: lighten($primary-color, 10%);
$secondary-color: #da121e;
$secondary-color-hover: darken($secondary-color, 10%);

$color-alternate: #da121e;
$primary-bg: #dddd;
$secondary-bg: #f8f8f8;

$alternate-bg: #ad0109;
$color_icons: black;

$white-text: white;

$color-error: #b30000;
$color-success: #008000;
$color-warning: #f58221;
$color-border: #c1c1c3;
$primary-text: #000;
$color-additional: #999999;
$color-gray: #787985;
$color-light-gray: #d8dfe9;
