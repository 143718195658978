.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: $secondary-color !important;
}
.datepicker {
    &.error {
        fieldset {
            border-color: #d32f2f;
        }
        .MuiFormLabel-root {
            color: #d32f2f;
        }
    }
}
.MuiDialog-scrollPaper {
    .MuiDialogActions-root {
        justify-content: space-between;
        padding: 16px 24px;
    }
    .MuiButton-root:nth-child(1) {
        background-color: $primary-color !important;
        color: white;
    }
    .MuiButton-root:nth-child(2) {
        background-color: $secondary-color !important;
        color: white;
    }
}
